* {
    $cursor: url('./cursors/default.png') 0 0, auto;
    cursor: $cursor;
}
button, .leaflet-popup-close-button, a {
    $cursor_active: url('./cursors/active.png') 0 0, auto !important;
    cursor: $cursor_active;
    * {
        cursor: $cursor_active;
        path {cursor: $cursor_active;}
    }
}
.leaflet-disabled, *:disabled , button.disabled {
    $cursor_inactive: url('./cursors/disabled.png') 0 0, auto !important;
    cursor: $cursor_inactive;
}

.leaflet-grab, .leaflet-dragging, .leaflet-grab, .leaflet-interactive {
    $cursormap: url('./cursors/default-map.png') 32 32, auto !important;
    cursor: $cursormap;
}
.leaflet-marker-icon:hover, .leaflet-marker-icon:active {
    $cursormap_active: url('./cursors/active-map.png') 32 32, auto !important;
    cursor: $cursormap_active;
}