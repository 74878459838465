@import '../../assets/general.scss';

$navbar-button-scale: 1.35;

$navbar-button-height: calc(130px * #{$navbar-button-scale});
$navbar-button-width: calc(60px * #{$navbar-button-scale});

navbar {
    buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        button {
            position: relative;
            width: $navbar-button-height;
            height: $navbar-button-width;
            background-color: $navbar-button-default;
            border: solid 2px $navbar-button-border-default;
            margin-left: 4px;
            margin-right: 4px;

            &:hover {
                background-color: $navbar-button-hover;
                border: solid 2px $navbar-button-border-hover;
            }

            &:active {
                background-color: $navbar-button-active;
                border: solid 2px $navbar-button-border-active;
            }

            &:hover::before, &:active::before {
                content: '';
                opacity: 0.8;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: url('../../assets/bg_button.png');
                background-repeat: no-repeat;
                background-size: cover;
            }

            svg, img {
                position: absolute;
                width: auto;
                transform: translate(-50%, -50%);
                height: 50px;
                top: 40%;
                pointer-events: none;
            }

            div, span {
                position: absolute;
                color: white;
                bottom: 5px;
                width: calc(100% - 12px);
            }
        }
    }
}
